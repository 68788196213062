import { type PropsWithChildren, useCallback, useState } from 'react';
import { DialogsContext } from './dialogsContext';
import type { DialogsContextType } from './dialogsContext';
import type { TeacherDialogIds, TeacherDialogProps } from '../types';
export const DialogsContextProvider = ({ children }: PropsWithChildren) => {
    const [activeDialogsState, setActiveDialogsState] = useState<
        DialogsContextType['activeDialogs']
    >({});

    const openDialog = useCallback(
        (id: TeacherDialogIds, props?: TeacherDialogProps) =>
            setActiveDialogsState((prevState) => ({
                ...prevState,
                [id]: { id, props },
            })),
        [setActiveDialogsState],
    );

    const hideDialog = useCallback(
        (dialogId: TeacherDialogIds) =>
            setActiveDialogsState((prevState) => {
                const { [dialogId]: _, ...restDialogs } = prevState;

                return restDialogs;
            }),
        [setActiveDialogsState],
    );

    const hideAllDialogs = useCallback(() => setActiveDialogsState({}), [setActiveDialogsState]);

    return (
        <DialogsContext.Provider
            value={{ activeDialogs: activeDialogsState, openDialog, hideDialog, hideAllDialogs }}
        >
            {children}
        </DialogsContext.Provider>
    );
};
