export enum TeacherDialogIds {
    CalendarConnection = 'calendar-connection',
}

export interface TeacherDialogProps {
    [key: string]: unknown;
}

export interface TeacherDialogBaseProps {
    hideDialog: () => void;
}

export interface TeacherDialog {
    id: TeacherDialogIds;
    props?: TeacherDialogProps;
}
