import { createContext, useContext } from 'react';
import type { TeacherDialog, TeacherDialogIds } from '../types';

export interface DialogsContextType {
    activeDialogs: Record<TeacherDialogIds, TeacherDialog> | Record<string, never>;
    openDialog: (dialogId: TeacherDialogIds) => void;
    hideDialog: (dialogId: TeacherDialogIds) => void;
    hideAllDialogs: () => void;
}

export const DialogsContext = createContext<DialogsContextType>({
    activeDialogs: {},
    openDialog: () => undefined,
    hideDialog: () => undefined,
    hideAllDialogs: () => undefined,
});

export const useDialogsContext = () => useContext(DialogsContext);
